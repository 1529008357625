<template>
  <div id="journeys-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/journeys/add" class="btn btn-primary mb-5">Journey hinzufügen</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Make Customer Journey Module as main unit with Link Tree Pages as Journey steps</li>
      <li>Be able to name Steps and connect them into one Journey with Channels in the beginning and KPIs at the end</li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
      <li>Connect to <router-link to="/links">Link Tree</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
    </ul>-->

    <IntroBanner v-if="!(journeys && journeys.length)" link="/journeys/add"></IntroBanner>

    <div v-for="(journey, index) in journeys" class="card mb-5" :key="index">
      <router-link :to="'/journeys/edit/' + journey.id" class="edit-button">
        <i class="fad fa-pencil"></i>
      </router-link>
      <div class="remove-button" @click="remove(journey.id)">
        <i class="fas fa-times"></i>
      </div>
      <div class="card-header">
        <h3 class="mb-0">{{ journey.title }}</h3>
      </div>
      <div class="card-body">
        <div class="row align-items-start">
          <div class="col-lg-8" style="padding-top:23px">
            <div v-for="(step, index) in journey.steps" class="row border-bottom mb-3" :key="'s' + index">
              <div class="col-3 pb-2">
                <img v-if="step.node" :src="getImg(step.node, 'thumb')" :alt="step.node.title" class="img-fluid">
                <NodeLinks :node="step.node" class="mt-2"></NodeLinks>
              </div>
              <div class="col-4">
                <h4 class="h6 mb-2">{{ step.name }}</h4>
                <p><code class="small">{{ step.nodeUrl || step.nodeUrl }}</code></p>
                <p class="text-muted">{{ step.node.pagetype }}</p>
                <p class="small">{{ step.description }}</p>
              </div>
              <div class="col-5">
                <div v-for="(attribute, index) in attributes" :key="'a' + index">
                  <div class="card mb-2">
                    <div class="card-body p-2">
                      <h6 class="card-title mb-1">{{ attribute.title }}:</h6>
                      <ul class="mb-0">
                        <li v-for="(value, index) in step.attributes[attribute.id]" :key="'m' + index">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedMetric" class="col-lg-4 order-2">
            <Funnel :values="journey.steps.map(step => step.metrics[selectedMetric.id] || 1)"
                    :tooltips="journey.steps.map(step => step.name)"
                    :height="88.5"
                    :full-width="true"
                    :show-values="true"
            ></Funnel>
            <div class="btn-group btn-block mt-3">
              <button v-for="(metric, index) in metrics"
                  @click="selectedMetric = metric"
                  class="btn"
                  :class="selectedMetric.id === metric.id ? 'btn-primary' : 'btn-secondary'"
                  :key="index"
              >
                {{ metric.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NodeLinks from "@/components/NodeLinks";
import Funnel from "@/components/Funnel";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Journeys',
  components: {
    IntroBanner,
    Funnel,
    NodeLinks
  },
  data() {
    return {
      selectedMetric: null,
    }
  },
  computed: {
    journeys() {
      return this.project.journeys.map(journey => {
        journey.steps.map(step => {
          step.node = this.findNode(step.nodeUrl);
          if (step.node.metrics) {
            step.metrics = step.node.metrics;
          } else {
            step.metrics = this.project.settings.nodeMetrics.reduce((acc, met) => {
              acc[met] = step.metrics[met] || 0;
              return acc;
            }, {});
          }
          if (step.node.attributes) {
            step.attributes = step.node.attributes;
          } else {
            step.attributes = this.project.settings.nodeAttributes.reduce((acc, att) => {
              acc[att] = step.attributes[att] || [];
              return acc;
            }, {});
          }
          return step;
        })
        return journey;
      });
    },
    metrics() {
      return this.project.settings.nodeMetrics || [];
    },
    attributes() {
      return this.project.settings.nodeAttributes || [];
    },
  },
  methods: {
    remove(id) {
      let journey = this.project.journeys.find(j => j.id === id);
      if (confirm('Remove "' + journey.title + '" from Journeys?')) {
        let index = this.project.journeys.findIndex(j => j.id === id);
        this.project.journeys.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'journeys', data: this.$store.getters.getProject.journeys})
            .then(res => {
              console.debug(res);
            });
      }
    },
  },
  mounted() {
    new Promise(resolve => {
      let interval = setInterval(() => {
        if (!this.$store.state.dataRequestPending) {
          resolve();
          clearInterval(interval);
        }
      }, 100);
    })
        .then(() => {
          this.selectedMetric = this.project.settings.nodeMetrics[0];
        });
  }
}
</script>

<style lang="scss">
.img-fluid {
  width: 100%;
}
</style>